// colors
$wild-sand: #f6f6f6;
$alabaster: #f7f7f7;
$color-primary: #00DB87;
$color-primary-dark: #00bd74;
$color-secondary: #f74e00;
$silver: #c4c4c4;
$mercury: #e5e5e5;
$mine-shaft: #383838;
$mine-shaft2: #212121;
$dove-gray: #727272;
$silver-chalice: #afafaf;
$gray: #919191;
$gray2: #878787;

// fonts
$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Times New Roman';

// screen sizes
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

// other
$default-transition: 250ms ease-in-out;
