@use 'variables' as *;
@import 'tailwindcss/tailwind.css';
@import 'node_modules/nprogress/nprogress.css';
// Font Awesome 5
@import 'node_modules/@fortawesome/fontawesome-free/css/fontawesome.css';
@import 'node_modules/@fortawesome/fontawesome-free/css/solid.css';
// @import 'node_modules/@fortawesome/fontawesome-free/css/regular.css';
@import 'node_modules/@fortawesome/fontawesome-free/css/brands.css';



body {
  margin: 0;
  font-family: $font-primary;
  color: $mine-shaft;
}

p {
  margin: 1rem auto;
}

a {
  &:hover {
    text-decoration: underline;
  }
}

.color {
  &-white {
    color: white;
  }

  &-primary {
    color: $color-primary;
  }

  &-red {
    color: red;
  }
}

h1 {
  font-size: 1.6rem;
  line-height: 2.1rem;
  font-weight: 700;
  color: $mine-shaft;
  margin: 1rem 0;
}

input, textarea {
  border-radius: 0.625rem;
  outline-color: $color-primary;
  color: $mine-shaft;
  border: 0.0625rem solid $silver-chalice;
  padding: 10px;
}

.container {
  @media screen and (max-width: $sm) {
    padding: 0 1rem;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.button {
  padding: 0.5rem 2rem;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 0.9375rem/1rem $font-primary;
  letter-spacing: 0.01875rem;
  user-select: none;

  &-primary {
    background-color: $color-primary;
    color: white;
    border: 2px solid $color-primary;

    &:hover {
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
    }
  }

  &-secondary {
    border: 2px solid #817171;
    color: $mine-shaft2;

    &:hover {
      background-color: $mercury;
    }
  }

  &:disabled {
    pointer-events: none;
    opacity: .5;
  }

  &-contrast{
    background-color: $mine-shaft2;
    color: white;
    border: 2px solid $mine-shaft2;
    font-weight: 800;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    border-radius: 0.6rem;
    padding: 0.7rem 2rem;

    &.full-width {
      width: 100%;
    }

    &:hover {
      color: $mine-shaft2;
      background-color: $color-primary;
    }
  }
}

.fontsize {
  &-14 {
    font-size: 0.875rem;
  }
}

.custom-backdrop-blur {
  background-color: rgba(255, 255, 255, .95);
}

.form {
  &-error {
    border-color: red;

    &-msg {
      font-size: 0.85rem;
      color: red;
    }
  }
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .custom-backdrop-blur {
    background-color: transparent;
    -webkit-backdrop-filter: blur(0.2rem);
    backdrop-filter: blur(0.2rem);
  }
}
